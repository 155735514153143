import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 防抖
import Uitls from 'uitls'
const uitls = new Uitls({
	url: '',
})
import Cookies from 'js-cookie'

import axios from 'axios'
import VueAxios from 'vue-axios'

import $requst from './utils/Http.js'
Vue.prototype.$request = $requst
Vue.prototype.$uitls = uitls

Vue.prototype.$cookies = Cookies

Vue.use(VueAxios, axios)
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


let arr = ['/', '/login', '/register']

router.beforeEach((to, from, next) => {
    // console.log(!localStorage.getItem('token')==false)
    if (localStorage.getItem('token') || arr.includes(to.fullPath)) {
        next()
    } else {
        ElementUI.Message({
            message: '您还未登录,请先登录!',
            type: 'error',
            duration: 1500,
            offset: 100
        })
        next('/login')
    }
})