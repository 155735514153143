import axios from "axios"
import Cookies from 'js-cookie'
import router from '@/router'

// var baseURL = 'http://shuhuaxueyuan.com/index.php'//本地地址
var baseURL = 'https://artxt.szart.cn/api/public/index.php'//线上地址

const instance = function(url) {
    // console.log(url)
    return axios({
        method: url.method || 'get',
        url: baseURL + url.url,
        headers: {
            token: Cookies.get('token') || '',
        },
        data: url.data
    }).then(res => {
        return res.data
    }).catch(err => {

    })

}


// 导出
export default instance