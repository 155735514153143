import Vue from "vue";
import VueRouter from "vue-router";

// 首页
import Home from "../views/home/Home.vue";
// 登录页
const Login = () => import("@/views/login/Login");
//注册页
const Register = () => import("@/views/register/Register");
// 主页
const Dashboard = () => import("@/views/index/Dashboard");
Vue.use(VueRouter);

// 解决从“/”到“/dashboard”的导航被取消，使用一个新的导航的问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/", //登录页
    component: Login,
    hidden: true,
  },
  {
    path: "/login", //登录页
    component: Login,
    hidden: true,
  },
  {
    name: "register", //注册
    component: Register,
    path: "/register",
  },
  {
    path: "/",
    component: Home,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        component: Dashboard,
        meta: {
          title: "首页", //首页
        },
      },
      // {
      //     path: "/login",
      //     component: () => import('@/views/login/Login'),
      //     alwaysShow: true,
      //     name: "login",
      //     meta: {
      //         title: '中国美术学院社会美术水平考试：机构报名管理系统'
      //     },
      //     children: []
      // }, {
      //     path: "/register",
      //     component: () => import('@/views/register/Register'),
      //     alwaysShow: true,
      //     name: "register",
      //     meta: {
      //         title: '注册'
      //     },
      //     children: []
      // },
      {
        path: "/enrollAdmin",
        component: () => import("@/views/enrollAdmin/EnrollAdmin"),
        alwaysShow: true,
        name: "enrollAdmin",
        meta: {
          title: "报名管理",
          noCache: true,
          // keepAlive: true // 该路由会被缓存
        },
        children: [],
      },
      {
        path: "/editEnrollAdmin",
        component: () => import("@/views/enrollAdmin/EditEnrollAdmin"),
        alwaysShow: true,
        name: "editEnrollAdmin",
        meta: {
          title: "报名管理编辑",
          noCache: true,
        },
      },
      {
        path: "/QRcode",
        component: () => import("@/views/QRcode/QRcode"),
        alwaysShow: true,
        name: "QRcode",
        meta: {
          title: "机构二维码",
          noCache: true,
        },
        children: [],
      },
      {
        path: "/batchImport",
        component: () => import("@/views/batchImport/BatchImport"),
        alwaysShow: true,
        name: "batchImport",
        meta: {
          title: "批量导入",
          noCache: true,
          // keepAlive: true // 该路由会被缓存
        },
        children: [],
      },
      {
        path: "/addBatchImport",
        component: () => import("@/views/batchImport/AddBatchImport"),
        alwaysShow: true,
        name: "addBatchImport",
        meta: {
          title: "批量导入新增",
          noCache: false,
        },
        children: [],
      },
      {
        path: "/editBatchImport",
        component: () => import("@/views/batchImport/EditBatchImport"),
        alwaysShow: true,
        name: "editBatchImport",
        meta: {
          title: "批量导入编辑",
          noCache: false,
        },
        children: [],
      },
      {
        path: "/onlineMsg",
        component: () => import("@/views/onlineMsg/OnlineMsg"),
        alwaysShow: true,
        name: "onlineMsg",
        meta: {
          title: "在线留言",
          noCache: true,
          // keepAlive: true // 该路由会被缓存
        },
        children: [],
      },
      {
        path: "/publishMsg",
        component: () => import("@/views/onlineMsg/PublishMsg"),
        alwaysShow: true,
        name: "publishMsg",
        meta: {
          title: "发表留言",
          noCache: false,
        },
        children: [],
      },
      {
        path: "/msgDetails",
        component: () => import("@/views/onlineMsg/MsgDetails"),
        alwaysShow: true,
        name: "msgDetails",
        meta: {
          title: "留言详情",
          noCache: false,
        },
        children: [],
      },
      {
        path: "/batchProcessing",
        component: () => import("@/views/batchProcessing/batchProcessing"),
        alwaysShow: true,
        name: "batchProcessing",
        meta: {
          title: "批量处理证件图片",
          noCache: false,
        },
        children: [],
      },
      {
        path: "/artwork",
        component: () => import("@/views/artwork/artwork"),
        alwaysShow: true,
        name: "artwork",
        meta: {
          title: "批量处理作品图片",
          noCache: false,
        },
        children: [],
      },
      {
        name: "newpage", //用于跳转刷新
        path: "/newpage",
        component: () => import("@/views/Emptypage/Newpage"),
      },
    ],
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
