<!-- 首页 -->
<template>
  <div class="home">
    <el-container>
      <el-aside :class="isCollapse ? 'scrollbar' : 'not-text'">
        <el-scrollbar :class="isCollapse ? 'scrollbar' : ''">
          <!-- 头像与名字 -->
          <!-- logo -->
          <img :src="logoimg" class="img" />
          <!-- <div class="headImg-name">
                        <div class="headImg">
                            <img src="../../assets/logo.png" >
                        </div>
                        <div class="name">
                              {{ fullName }}
                        </div>
                    </div> -->
          <!-- 菜单 -->
          <el-menu
            :default-active="onRouts"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            :class="isCollapse ? 'scrollbar' : ''"
            background-color="#29726C"
            text-color="#fff"
            router
            :unique-opened="true"
            :collapse="isCollapse"
            active-text-color="#ffffff"
            :collapse-transition="false"
          >
            <el-menu-item
              v-for="(item, index) in nav"
              :index="item.path"
              :hide-timeout="0"
              v-show="item.noCache"
              :key="index"
            >
              <img :src="item.icon" style="padding: 0px 10px 0px 5px" />
              <span>{{ item.name }}</span>
            </el-menu-item>
            <el-menu-item @click="outLogin">
              <img
                src="../../assets/backOut.png"
                style="padding: 0px 10px 0px 5px"
              />
              <span>安全退出</span>
            </el-menu-item>
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <el-main :style="{ width: fullWidth - 230 + 'px' }">
        <el-container>
          <el-header
            style="
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              position: relative;
              z-index: 5;
              padding-left: 0px;
            "
          >
            <div
              class=""
              style="height: 50px; display: flex; align-items: center"
            >
              <span
                class="span_1"
                :class="rotate ? 'cartoon' : 'timing'"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                @click="toggleClick()"
              >
                <img src="../../assets/menuIcon.png" />
              </span>
              <div class="" style="color: #000000; font-size: 18px">
                中国美术学院社会美术水平考试：机构报名管理系统
              </div>
            </div>
            <!-- 下拉部分 -->
            <div class="t-right">
              <img src="../../assets/rightImg.png" />
              <div class="" style="cursor: pointer; padding: 0px 10px">
                <!-- 書畫藝術學院 -->
                {{ fullName }} - {{ name }}
              </div>
              <!-- 下拉菜单 -->
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <i class="el-icon-arrow-down"></i>
                </span>
                <el-dropdown-menu slot="dropdown" style="padding: 0px">
                  <!-- <el-dropdown-item class="personal"
                                        @click.native="personal">个人信息</el-dropdown-item>
                                    <el-dropdown-item class="modify">修改密码</el-dropdown-item> -->
                  <el-dropdown-item class="quit" @click.native="outLogin"
                    >退出系统</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-header>
          <el-main style="margin: 10px 20px; background-color: #ffffff">
            <router-view></router-view>
          </el-main>
        </el-container>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      fullWidth: document.documentElement.clientWidth, //屏幕宽度
      isCollapse: true, //判断是否展开关闭
      rotate: true, //判断是否旋转
      logoimg: require("../../assets/logo1@2x.png"),
      headImg: "", //头像
      fullName: "书画学院", //机构名字
      name: "王某某", //负责人名字
      nav: [
        {
          id: "1",
          name: "首页",
          icon: require("../../assets/home.png"),
          path: "/dashboard",
          noCache: true,
          listGroup: [],
        },
        {
          id: "2",
          name: "报名管理",
          icon: require("../../assets/bmguanl.png"),
          path: "/enrollAdmin",
          noCache: true,
          listGroup: [],
        },
        // {
        //     id: "3",
        //     name: "机构二维码",
        //     icon: require('../../assets/erweim.png'),
        //     path: "/QRcode",
        //     noCache: true,
        //     listGroup:[]
        // },
        // {
        //     id: "4",
        //     name: "批量导入",
        //     icon: require('../../assets/daoru.png'),
        //     path: "/batchImport",
        //     noCache: true,
        //     listGroup:[]
        // },
        {
          id: "5",
          name: "在线留言",
          icon: require("../../assets/liuyan.png"),
          path: "/onlineMsg",
          noCache: true,
          listGroup: [],
        },
        {
          id: "6",
          name: "报名管理编辑",
          icon: "",
          path: "/editEnrollAdmin",
          noCache: false,
          listGroup: [],
        },
        {
          id: "7",
          name: "批量导入新增",
          icon: "",
          path: "/addBatchImport",
          noCache: false,
          listGroup: [],
        },
        {
          id: "8",
          name: "批量导入编辑",
          icon: "",
          path: "/editBatchImport",
          noCache: false,
          listGroup: [],
        },
        {
          id: "9",
          name: "发表留言",
          icon: "",
          path: "/publishMsg",
          noCache: false,
          listGroup: [],
        },
        {
          id: "10",
          name: "留言详情",
          icon: "",
          path: "/msgDetails",
          noCache: false,
          listGroup: [],
        },
        {
          id: "11",
          name: "批量处理证件照片",
          icon: require("../../assets/daoru.png"),
          path: "/batchProcessing",
          noCache: true,
          listGroup: [],
        },
        {
          id: "12",
          name: "批量处理作品图片",
          icon: require("../../assets/daoru.png"),
          path: "/artwork",
          noCache: true,
          listGroup: [],
        },
      ],
      info: {},
      getName: "",
    };
  },

  mounted() {
    // 监听屏幕变化改变布尔值
    window.onresize = () => {
      // console.log(document.documentElement.clientWidth)
      if (
        document.documentElement.clientWidth <= 768 ||
        document.documentElement.clientWidth <= 1024
      ) {
        this.isCollapse = false;
      }
    };
  },
  created() {
    this.info = JSON.parse(localStorage.getItem("get_info"));
    console.log(this.info);
    this.fullName = this.info.name;
    this.name = this.info.principal;
  },
  watch: {
    //监听属性
  },
  methods: {
    toggleClick() {
      //点击图片旋转 左侧菜单弹入弹出
      this.isCollapse = !this.isCollapse;
      this.rotate = !this.rotate;
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    outLogin() {
      //退出登录
      this.$message({
        message: "退出成功",
        type: "success",
      });
      // localStorage.clear()
      localStorage.removeItem("token");
      localStorage.removeItem("get_info");
      // this.$router.go(0)
      this.$router.replace({
        path: "/",
      });
    },
    // abc(val) {//接收子传父
    //     // console.log(val)
    //     this.fullName=val.name
    //     this.name=val.principal
    // },
    // msg(val){//接收子传父
    //     this.fullName=val.name
    //     this.name=val.principal
    // }
  },
  computed: {
    onRouts() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  width: 100%;
  height: 100%;
}

.el-container {
  width: 100%;
  height: 100%;
}

/* 左边 */
.el-aside {
  background-color: #29726c;
  color: #333;
  text-align: center;
  transition: all 0.5s;
  width: 230px !important;
  /* line-height: 200px; */
}
/* logo */
.img {
  width: 189px;
  height: 97px;
  margin-top: 22px;
  margin-bottom: 40px;
}
// 头像 名称
.headImg-name {
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 34px 0px;
}
// 头像
.headImg {
  width: 94px;
  height: 94px;
  border-radius: 50%;
  background-color: #fff;
}
.headImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
// 名字
.name {
  margin: 5px 0px;
  color: #fff;
}
// 下拉菜单
.el-menu {
  background-color: #304156;
  text-align: left;
  width: 230px !important;
  line-height: 200px !important;
  border-right: 0px !important;
  overflow: hidden;
  transition: all 0.5s;
}
.el-menu-item {
  transition: all 0.5s;
  width: 230px !important;
  // margin-left: 20px;
  background-color: #29726c !important;
}
.el-menu-item:hover {
  background-color: #18bc9c !important;
}
::v-deep .el-menu-item-group__title {
  padding-top: 2px;
}
// ::v-deep .el-menu--collapse>.el-menu-item span{
//     visibility: visible !important;
//     display: contents !important;
// }
// 子菜单
.el-menu-item-group {
  background-color: #0c5650;
}

.el-menu-item.is-active {
  background: #18bc9c !important;
}

::v-deep .el-submenu__icon-arrow.el-icon-arrow-down {
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
}
.scrollbar {
  width: 60px !important;
  transition: all 0.5s;
}
.not-text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.timing {
  transition: all 0.5s;
}
.span_1 {
  height: 50px;
  width: 50px;
  text-align: center;
}
.span_1:hover {
  cursor: pointer;
}
//动画
.cartoon img {
  transform: rotate(-90deg);
  transition: all 0.5s;
}
// 右边
.el-main {
  background-color: #f0f3f5;
  color: #333;
  padding: 0px;
}
.el-header {
  background-color: #fff;
  color: #333;
  height: 50px !important;
  text-align: center;
  line-height: 50px;
}

.t-right {
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: #666;
}

.t-right img {
  cursor: pointer;
}
.t-right i {
  vertical-align: middle;
  font-size: 20px;
  // padding: 0px 5px;
  cursor: pointer;
}
::v-deep .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #efefef;
  color: #333;
}
// 退出
.quit {
  padding-left: 30px;
  background: url(../../assets/tuichu.png) 8px 10px no-repeat;
}
</style>
